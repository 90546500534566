import React from "react"
import Slider from "react-slick"
import Cloudinary from "cloudinary-core"
import _ from "lodash"
import GatsbyImage from "../gatsbyImage/GatsbyImage"
import { CarCard } from "./car-card"
import { combineCloudinaryWithImages } from "../../utils/images"

export const CarCarousel = ({ carCarousel }) => {
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.27,
          slidesToScroll: 1,
          infinite: false,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 2.25,
          slidesToScroll: 1,
          infinite: false,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 1700,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 1,
          infinite: false,
          initialSlide: 0,
        },
      },
    ],
  }
  return (
    <div className="carousel-container">
      <div className="section-title">
        <h1>{carCarousel.title}</h1>
      </div>
      <Slider className="appCarousel" {...settings}>
        {carCarousel.cars.map((car, index) => (
          <CarCard
            title={`${car?.item?.modelYear} ${car?.item?.make?.name} ${car?.item?.model}`}
            auctionName={car?.auction.name}
            salePrice={car?.salePrice}
            imageFluid={
              combineCloudinaryWithImages(car?.item, 392, 1)[0]?.fluid
            }
            slug={car?.slug}
            includeTag={car?.worldRecord}
          />
        ))}
      </Slider>
    </div>
  )
}
