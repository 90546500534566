import React from "react"
import Img from "gatsby-image"

const GatsbyImage = ({
  image,
  title,
  minHeight,
  className,
  fixedImage,
  alt,
  maxWidth,
}) => {
  return (
    <Img
      className={`gatsby-image ${className}`}
      fluid={image}
      alt={alt ?? title}
      style={{ minHeight: `${minHeight}px`, maxWidth: `${maxWidth}` }}
      fixed={fixedImage}
    />
  )
}

export default GatsbyImage
