import React from "react"
import { FooterLogo } from "../../../static/svg/FooterLogo"
import GoodingLogo from "../../../static/svg/GoodingLogo"

export const Footer = () => (
  <div className="footer">
    <div>
      <FooterLogo />
    </div>
    <div>
      <a href="https://www.goodingco.com/terms">TERMS & CONDITION</a> /{" "}
      <a href="https://www.goodingco.com/privacy">PRIVACY POLICY</a>
    </div>
    <div>
      <p>©2022 by Gooding & Company Inc. All Rights Reserved. MMXIX</p>
    </div>
  </div>
)
