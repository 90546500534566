import React from "react"

export const FooterLogo = () => (
  <svg
    width="210"
    height="16"
    viewBox="0 0 210 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.34259 15.6856V14.6213C8.04998 15.4137 6.52647 15.9998 5.50534 15.9998C2.46005 15.9998 0 13.5372 0 10.4928V5.50706C0 2.46178 2.46005 0 5.50534 0C8.36331 0 10.7206 2.17002 10.9925 4.96499L8.23729 5.52778C8.23729 4.02585 7.00726 2.81654 5.48462 2.79669C4.02499 2.79669 2.7941 4.006 2.7941 5.50706V10.4928C2.7941 11.9515 4.02499 13.1824 5.50534 13.1824C7.00726 13.1824 8.32188 12.1406 8.32188 10.4928V10.2217H6.67408V7.71852H11.0323V15.6856H8.34259Z"
      fill="#1C5BAE"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.5657 15.9998C16.5204 15.9998 14.0586 13.5372 14.0586 10.4928V5.50706C14.0586 2.46178 16.5204 0 19.5657 0C22.6109 0 25.0934 2.46178 25.0934 5.50706V10.4928C25.0934 13.5372 22.6109 15.9998 19.5657 15.9998ZM22.2977 5.52686C22.2977 4.0258 21.0685 2.81649 19.545 2.81649C18.0854 2.81649 16.8537 4.04565 16.8537 5.50701V10.4927C16.8537 11.9515 18.0854 13.1824 19.5658 13.1824C21.0685 13.1824 22.2977 11.9515 22.2977 10.4927V5.52686Z"
      fill="#1C5BAE"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M33.5199 15.9998C30.4746 15.9998 28.0137 13.5372 28.0137 10.4928V5.50706C28.0137 2.46178 30.4746 0 33.5199 0C36.5652 0 39.0485 2.46178 39.0485 5.50706V10.4928C39.0485 13.5372 36.5652 15.9998 33.5199 15.9998ZM36.2537 5.52679C36.2537 4.02573 35.0228 2.81642 33.5002 2.81642C32.0405 2.81642 30.8096 4.04558 30.8096 5.50694V10.4926C30.8096 11.9514 32.0405 13.1823 33.52 13.1823C35.0228 13.1823 36.2537 11.9514 36.2537 10.4926V5.52679Z"
      fill="#1C5BAE"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M47.8728 15.6857H42.3848V0.3125H47.8918C50.9371 0.3125 53.4213 2.77428 53.4213 5.81956V10.1795C53.4213 13.2256 50.9173 15.6857 47.8728 15.6857ZM50.6257 5.84025C50.6257 4.33833 49.3948 3.12988 47.892 3.12988H45.1816V12.8691H47.892C49.3948 12.8691 50.6257 11.6399 50.6257 10.1794V5.84025Z"
      fill="#1C5BAE"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M55.5918 15.6857V12.8691H58.3885V3.12991H55.5918V0.3125H63.7902V3.12991H61.1826V12.8691H63.7902V15.6857H55.5918Z"
      fill="#1C5BAE"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M75.5316 15.6857L69.9201 5.71512V15.6857H67.2305V0.3125H69.9201L75.4281 10.075V0.3125H78.1617V15.6857H75.5316Z"
      fill="#1C5BAE"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M89.822 15.6856V14.6213C89.5302 15.4137 88.0084 15.9998 86.9856 15.9998C83.9394 15.9998 81.4785 13.5372 81.4785 10.4928V5.50706C81.4785 2.46178 83.9394 0 86.9856 0C89.8436 0 92.2 2.17002 92.4711 4.96499L89.7175 5.52778C89.7175 4.02585 88.4875 2.81654 86.964 2.79669C85.5044 2.79669 84.2743 4.006 84.2743 5.50706V10.4928C84.2743 11.9515 85.5044 13.1824 86.9856 13.1824C88.4875 13.1824 89.8004 12.1406 89.8004 10.4928V10.2217H88.1535V7.71852H92.5134V15.6856H89.822Z"
      fill="#1C5BAE"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M106.076 13.5126C104.669 15.0292 103.044 15.9071 101.045 15.9071C98.2785 15.9071 96.2129 14.1704 96.2129 11.6024V11.5576C96.2129 9.51441 97.575 8.0427 99.9263 7.11996C98.8706 5.8908 98.3881 4.94562 98.3881 3.73804V3.69402C98.3881 1.67332 100.013 0.0488281 102.451 0.0488281C104.604 0.0488281 106.185 1.60686 106.185 3.51879V3.56282C106.185 5.51791 104.802 6.76865 102.561 7.55932L106.076 11.1398C106.757 10.1532 107.372 8.98701 107.92 7.71383L109.348 8.37243C108.668 9.82257 107.965 11.1398 107.129 12.2395L109.811 14.9844L108.492 15.9943L106.076 13.5126ZM105.022 12.4355L100.915 8.19644C98.8709 8.94309 97.9706 10.1956 97.9706 11.492V11.5361C97.9706 13.183 99.2887 14.4346 101.177 14.4346C102.627 14.4346 103.901 13.6664 105.022 12.4355ZM104.56 3.65103V3.60615C104.56 2.37612 103.636 1.47583 102.386 1.47583C101.002 1.47583 100.059 2.44259 100.059 3.69419V3.73821C100.059 4.63937 100.431 5.31955 101.595 6.57288C103.55 5.91342 104.56 4.96824 104.56 3.65103Z"
      fill="#1C5BAE"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M117.822 15.9998C114.776 15.9998 112.314 13.5372 112.314 10.4928V5.50706C112.314 2.46178 114.776 0 117.822 0C120.741 0 123.12 2.21146 123.329 5.09015L120.553 5.52692C120.553 4.02585 119.323 2.81654 117.822 2.79496C116.362 2.79496 115.151 4.006 115.151 5.50706V10.4928C115.151 11.9515 116.362 13.1824 117.822 13.1824C119.323 13.1824 120.553 11.9515 120.553 10.4928L123.329 10.9088C123.12 13.7875 120.741 15.9998 117.822 15.9998Z"
      fill="#1C5BAE"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M131.652 15.9998C128.607 15.9998 126.145 13.5372 126.145 10.4928V5.50706C126.145 2.46178 128.607 0 131.652 0C134.696 0 137.179 2.46178 137.179 5.50706V10.4928C137.179 13.5372 134.696 15.9998 131.652 15.9998ZM134.385 5.52678C134.385 4.02572 133.153 2.81641 131.631 2.81641C130.171 2.81641 128.939 4.04557 128.939 5.50693V10.4926C128.939 11.9514 130.171 13.1823 131.652 13.1823C133.153 13.1823 134.385 11.9514 134.385 10.4926V5.52678Z"
      fill="#1C5BAE"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M148.759 15.6857V7.65554L146.006 14.914L143.273 7.48808V15.6857H140.52V0.3125H143.44L146.006 7.02973L148.613 0.3125H151.554V15.6857H148.759Z"
      fill="#1C5BAE"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M161.333 10.0543H158.08V15.6857H155.305V0.3125H161.333C164.024 0.3125 166.192 2.48252 166.192 5.15233C166.214 7.86356 164.045 10.0543 161.333 10.0543ZM161.396 3.06592H158.059V7.36367H161.396C162.607 7.36367 163.587 6.40382 163.587 5.23594C163.566 4.02577 162.586 3.06592 161.396 3.06592Z"
      fill="#1C5BAE"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M175.352 15.6857V14.3503L174.915 12.8078H170.45L170.034 14.3503V15.6857H167.135V14.5368C167.135 14.3503 167.155 14.1846 167.239 14.018L171.326 0.3125H173.935L178.044 13.9757C178.086 14.1423 178.17 14.3305 178.17 14.5178V15.6857H175.352ZM172.641 4.69415L171.182 10.1183H174.165L172.641 4.69415Z"
      fill="#1C5BAE"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M189.287 15.6857L183.676 5.71512V15.6857H180.984V0.3125H183.676L189.183 10.075V0.3125H191.915V15.6857H189.287Z"
      fill="#1C5BAE"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M205.434 3.15094L201.554 9.07492V15.6868H198.738V9.07492L194.879 3.10951C194.712 2.9222 194.629 2.62959 194.629 2.35769V0.292969H197.465V1.89848L200.032 6.23766L202.868 1.89848V0.292969H205.643V2.35769C205.643 2.6503 205.579 2.9222 205.434 3.15094Z"
      fill="#1C5BAE"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M206.836 1.55345V1.54741C206.836 0.931105 207.337 0.414062 207.972 0.414062C208.607 0.414062 209.1 0.925063 209.1 1.54051V1.54741C209.1 2.16372 208.599 2.68076 207.964 2.68076C207.331 2.68076 206.836 2.17063 206.836 1.55345ZM208.984 1.54737V1.54047C208.984 0.980267 208.548 0.5271 207.972 0.5271C207.394 0.5271 206.955 0.986309 206.955 1.54737V1.55342C206.955 2.11534 207.39 2.56765 207.964 2.56765C208.545 2.56765 208.984 2.10757 208.984 1.54737ZM207.58 1.00195H208.039C208.174 1.00195 208.279 1.04252 208.35 1.11244C208.404 1.16423 208.434 1.23846 208.434 1.32651V1.33169C208.434 1.49828 208.335 1.60013 208.195 1.6407L208.472 2.02741H208.236L207.989 1.67264H207.984H207.776V2.02741H207.58V1.00195ZM208.032 1.50255C208.159 1.50255 208.235 1.4404 208.235 1.34373V1.33855C208.235 1.23497 208.159 1.17886 208.032 1.17886H207.777V1.50255H208.032Z"
      fill="#1C5BAE"
    />
  </svg>
)
