import React from "react"

export const LastSection = ({
  sellCarText,
  smallText,
  sellCarTags,
  slug,
  buttonText,
}) => (
  <div className="last-section">
    <div className="last-section-container">
      <div className="text-wrapper">
        <h1>{sellCarText}</h1>
      </div>
      <div className="buttons">
        {sellCarTags.map(card => (
          <button disabled={true}>
            <img src={card?.img?.file?.url} />
            {card.text}
          </button>
        ))}
      </div>
      <div>
        <p>{smallText}</p>
      </div>
      <div className="submitButton">
        <button>
          <a href={`${slug}`}>{buttonText}</a>
        </button>
      </div>
    </div>
  </div>
)
