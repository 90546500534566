import React from "react"
import { LeftSideGoodingLogo } from "../../../static/svg/LeftSideGoodingLogo"
import { RightSideGoodingLogo } from "../../../static/svg/rightSideGoodingLogo"
import GatsbyImage from "../gatsbyImage/GatsbyImage"

export default ({ iconsData }) => (
  <div>
    <div className="headline">
      <h1>{iconsData?.headLine}</h1>
    </div>
    <div className="grid-container">
      {iconsData?.micrositeReasonIcons.map(icon => (
        <div key={icon.contentful_id} className="grid-item">
          <GatsbyImage image={icon?.iconImage?.fluid} />
          <h3>{icon?.iconTitle}</h3>
        </div>
      ))}
    </div>
  </div>
)
