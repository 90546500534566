import * as React from "react"
export const RightSideGoodingLogo = () => (
  <svg
    width="547"
    height="336"
    viewBox="0 0 547 336"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_270_12764)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M531.166 171.649C335.593 171.649 177.049 13.1052 177.049 -182.468C177.049 -378.042 335.593 -536.586 531.166 -536.586C621.915 -536.586 704.692 -502.45 767.355 -446.318L695.66 -374.623C651.434 -412.518 593.973 -435.41 531.166 -435.41C391.47 -435.41 278.225 -322.165 278.225 -182.469C278.225 -42.7736 391.47 70.4719 531.166 70.4719C595.078 70.4719 653.454 46.7676 697.982 7.67108L769.633 79.3224C706.705 136.677 623.019 171.649 531.166 171.649ZM881.53 -130.722C884.003 -147.613 885.284 -164.891 885.284 -182.468C885.284 -201.354 883.805 -219.894 880.958 -237.98L827.615 -184.637L881.53 -130.722Z"
        fill="#F5F5F5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 -182.471C0 103.905 232.153 336.058 518.529 336.058C804.905 336.058 1037.06 103.905 1037.06 -182.471C1037.06 -203.745 1035.77 -224.851 1033.24 -245.703H931.13C931.13 -245.702 931.13 -245.7 931.13 -245.699H758.824V-144.523H934.179C915.007 68.1907 736.234 234.881 518.529 234.881C288.031 234.881 101.176 48.0261 101.176 -182.471C101.176 -412.969 288.031 -599.824 518.529 -599.824C632.89 -599.824 739.759 -553.663 817.817 -473.35H947.843C931.979 -496.723 914.201 -518.812 894.68 -539.38C797.424 -641.849 662.638 -701 518.529 -701C232.153 -701 0 -468.847 0 -182.471Z"
        fill="#F5F5F5"
      />
    </g>
    <defs>
      <clipPath id="clip0_270_12764">
        <rect width="547" height="336" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
