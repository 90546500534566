import React, { useState, useEffect } from "react"
import _ from "lodash"
import GatsbyImage from "../gatsbyImage/GatsbyImage"
import Background from "../background"

const HeroSection = ({
  title,
  image,
  titleWithoutMargin,
  titleFullWidth,
  children,
  subTitle,
  video,
  buttonTitle,
  carSlug,
  carImage,
}) => {
  return (
    <div className="appHeroSection" role="presentation">
      <div>
        {image && !video && (
          <GatsbyImage
            image={image?.fluid}
            title={image?.title}
            alt={image?.description ?? image?.alt}
            className="gatsbyImageWrapper"
            maxWidth={"1800px"}
          />
        )}

        <div className={`wrapper ${titleFullWidth ? "wrapperFullWidth" : ""}`}>
          {title && (
            <h1 className={titleWithoutMargin ? "mb-0" : ""}>{title}</h1>
          )}
          {subTitle && <h2>{subTitle}</h2>}

          <button className="app-white-link-button">
            <a href={`${process.env.GATSBY_API_URL}${carSlug}`}>
              {buttonTitle}
            </a>
          </button>
        </div>
      </div>
    </div>
  )
}
export default HeroSection
