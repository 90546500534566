import React from "react"
import { ReadMore } from "../../../static/svg/ReadMore"
import GatsbyImage from "../gatsbyImage/GatsbyImage"
import Button from "../shared/Button"

export const ArticleCard = ({ title, logo, articleImage, slug, index }) => (
  <div className={`article-card ${index !== 3 && index !== 7 ? "margin" : ""}`}>
    <div className="article-card-header">
      <div>
        <img src={logo} />
      </div>
    </div>
    <GatsbyImage className="article-image" image={articleImage} />
    <div className="article-footer">
      <h1>{title}</h1>
      <div className="link-section">
        <ReadMore />
        <a href={slug}>Read More</a>
      </div>
    </div>
  </div>
)
