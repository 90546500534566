import React from "react"
import { ArticleCard } from "./article-card"

export const Articles = ({ articleData }) => (
  <div className="article-section">
    <div className="article-header">
      <h1>{articleData.sectionTitle}:</h1>
      <hr></hr>
    </div>
    <div className="article-cards">
      {articleData?.articles?.map((card, index) => (
        <ArticleCard
          title={card?.articleTitle}
          logo={card?.logo?.file.url}
          articleImage={card?.articleImage?.fluid}
          slug={card?.articleSlug}
          index={index}
        />
      ))}
    </div>
  </div>
)
