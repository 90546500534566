import { navigate } from "gatsby-link"
import React from "react"

import GatsbyImage from "../gatsbyImage/GatsbyImage"

export const CarCard = ({
  title,
  auctionName,
  imageFluid,
  salePrice,
  slug,
  includeTag,
}) => (
  <div
    onClick={() => navigate(`${process.env.GATSBY_API_URL}/lot/${slug}`)}
    className="car-card"
  >
    <div className="card-header">
      <div>
        <h1>{title}</h1>
        <p>{auctionName}</p>
      </div>
      {includeTag && (
        <div className="tag">
          <h3>World Record</h3>
        </div>
      )}
    </div>
    <div className="img">
      <GatsbyImage image={imageFluid} />
    </div>
    <div className="card-footer">
      <p>sold for:</p>
      <h1>${Number(salePrice).toLocaleString("en-US")}</h1>
    </div>
  </div>
)
