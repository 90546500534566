import * as React from "react"
export const LeftSideGoodingLogo = () => (
  <svg
    width="450"
    height="1037"
    viewBox="0 0 450 1037"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_270_12779)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M-44.8337 872.649C-240.407 872.649 -398.951 714.105 -398.951 518.532C-398.951 322.958 -240.407 164.414 -44.8337 164.414C45.9154 164.414 128.692 198.55 191.355 254.682L119.66 326.377C75.4342 288.482 17.9733 265.59 -44.8344 265.59C-184.53 265.59 -297.775 378.835 -297.775 518.531C-297.775 658.226 -184.53 771.472 -44.8344 771.472C19.0784 771.472 77.4546 747.767 121.982 708.671L193.634 780.322C130.705 837.677 47.0197 872.649 -44.8337 872.649ZM309.032 532.014C309.199 527.541 309.284 523.046 309.284 518.532C309.284 499.646 307.805 481.106 304.958 463.02L272.498 495.48L309.032 532.014Z"
        fill="#F5F5F5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M-576 518.529C-576 804.905 -343.847 1037.06 -57.4709 1037.06C228.905 1037.06 461.058 804.905 461.058 518.529C461.058 497.255 459.774 476.149 457.237 455.297H355.13C355.13 455.298 355.13 455.3 355.13 455.301H182.824V556.477H358.179C339.007 769.191 160.234 935.881 -57.4715 935.881C-287.969 935.881 -474.824 749.026 -474.824 518.529C-474.824 288.031 -287.969 101.176 -57.4715 101.176C56.8898 101.176 163.759 147.337 241.817 227.65H371.843C355.979 204.277 338.201 182.188 318.68 161.62C221.424 59.1512 86.6382 0 -57.4709 0C-343.847 0 -576 232.153 -576 518.529Z"
        fill="#F5F5F5"
      />
    </g>
    <defs>
      <clipPath id="clip0_270_12779">
        <rect width="450" height="1037" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
