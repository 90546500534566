import {
  Step,
  StepConnector,
  StepContent,
  StepLabel,
  Stepper,
} from "@material-ui/core"
import React, { useState } from "react"
import { useEffect } from "react"

export const SellCar = ({ data, slug }) => {
  const steps = data?.stepsSection
  const [activeStep, setActiveStep] = React.useState(0)

  const handleNext = index => {
    setActiveStep(index)
  }
  const handlePrev = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const [width, setWidth] = useState(0)
  const breakPoint = 1300
  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window?.innerWidth))
    setWidth(window.innerWidth)
  }, [])

  return (
    <div className="sell-car">
      <div className="question">
        <h1>How We Will Sell Your Ferrari for Top Value</h1>
        <button>
          <a href={data?.buttonSlug}>{data?.buttonTitle}</a>
        </button>
      </div>
      <div className="stepper">
        <Stepper
          connector={<div className="connector" />}
          activeStep={activeStep}
          orientation="vertical"
        >
          {steps.map((step, index) => (
            <Step key={index}>
              <StepLabel
                onClick={() => {
                  handleNext(index)
                }}
              >
                <img src={step?.image?.file?.url} />
                <div
                  className={`${index === activeStep && "active"} text-wrapper`}
                >
                  <h1 className={index === activeStep && "active"}>
                    {step?.title}
                  </h1>
                  {index !== activeStep &&
                    index !== 2 &&
                    width < breakPoint && <hr className="mobile" />}
                </div>
              </StepLabel>
              {index !== activeStep && index !== 2 && width > breakPoint && (
                <div className="divider" />
              )}
              <StepContent className={activeStep === index && "active"}>
                {step?.text.map(text => (
                  <h1>{text}</h1>
                ))}
                {index === activeStep && index !== 2 && (
                  <hr className="marginFull" />
                )}
              </StepContent>
              {index !== 2 && index !== activeStep && (
                <StepConnector
                  className={`${activeStep === index && "active"}`}
                ></StepConnector>
              )}
            </Step>
          ))}
        </Stepper>
      </div>
    </div>
  )
}
