import { graphql } from "gatsby"
import Car from "./car"
import React from "react"
export default ({ data }) => {
  return <Car carData={data.contentfulMicrositeCar}></Car>
}
export const pageQuery = graphql`
  query MyQuery($slug: String!) {
    contentfulMicrositeCar(slug: { eq: $slug }) {
      heroSection {
        buttonSlug
        buttonText
        goodingDescription {
          goodingDescription
        }
        heroSectionBackground {
          fluid(maxWidth: 1800, quality: 100) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        carImage {
          title
          fluid(maxWidth: 1004, quality: 100) {
            ...GatsbyContentfulFluid_withWebp
          }
          file {
            url
          }
        }
        heroSectionQuestion
      }
      reasonsSection {
        micrositeReasonIcons {
          iconImage {
            fluid(maxWidth: 250, quality: 100) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          iconTitle
          contentful_id
        }
        headLine
      }
      carCarousel {
        title
        includeBanner
        cars {
          auction {
            name
            webpage__auction {
              slug
            }
          }
          salePrice
          slug
          item {
            ... on ContentfulVehicle {
              id
              title
              make {
                name
              }
              modelYear
              model
              cloudinaryImagesCombined {
                public_id
                height
                width
                context {
                  custom {
                    alt
                  }
                }
              }
            }
            ... on ContentfulAutomobilia {
              id
              title
              cloudinaryImagesCombined {
                public_id
                height
                width
              }
            }
          }
          worldRecord
        }
      }
      articles {
        sectionTitle
        articles {
          articleSlug
          articleTitle
          logo {
            fluid(quality: 100, maxWidth: 208) {
              ...GatsbyContentfulFluid_withWebp
            }
            file {
              url
            }
          }
          articleImage {
            fluid(quality: 100) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
      sellCar {
        buttonSlug
        buttonTitle
        title
        stepsSection {
          image {
            file {
              url
            }
          }
          text
          title
        }
      }
      multichannelSection {
        cardSubTitle
        cardTitle
        icon {
          file {
            url
          }
        }
        photo {
          file {
            url
          }
        }
      }
      multiChannelTitle
      sellCarText
      sellCarTags {
        text
        img {
          file {
            url
          }
        }
      }
      smallText
    }
  }
`
