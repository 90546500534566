import React from "react"
import { Styled, ThemeProvider } from "theme-ui"
import { LeftSideGoodingLogo } from "../../../static/svg/LeftSideGoodingLogo"
import { RightSideGoodingLogo } from "../../../static/svg/rightSideGoodingLogo"
import { Articles } from "../../components/articles"
import Background from "../../components/background"
import { CarCarousel } from "../../components/car-carousel"
import GatsbyImage from "../../components/gatsbyImage/GatsbyImage"
import Header from "../../components/header"
import HeroSection from "../../components/hero-section"
import IconsSection from "../../components/icons-section/IconsSection"
import { Multichannel } from "../../components/multi-channel"

import { SellCar } from "../../components/sell-car"
import theme from "../../theme"
import { WHITE } from "../../utils/colorsConstants"
import { LastSection } from "../../components/last-section"
import { Footer } from "../../components/footer"
const Car = ({ carData }) => {
  const heroData = carData?.heroSection
  const iconsData = carData?.reasonsSection
  const carouselData = carData?.carCarousel
  const articleData = carData?.articles
  const sellCarData = carData?.sellCar
  const multiChannelData = carData?.multichannelSection
  const multiChannelTitle = carData?.multiChannelTitle
  return (
    <div className="container">
      <div>
        <Header
          color={WHITE}
          showRegisterButton={false}
          showCarButton={true}
          carButton={heroData?.buttonText}
          carSlug={heroData?.buttonSlug}
          isCar="true"
        />
        <HeroSection
          title={heroData?.heroSectionQuestion}
          image={heroData?.heroSectionBackground}
          subTitle={heroData?.goodingDescription.goodingDescription}
          buttonTitle={heroData?.buttonText}
          carImage={heroData?.carImage.fluid}
          carSlug={heroData?.buttonSlug}
        />
        <img className="hero-car" src={heroData?.carImage?.file?.url} />
        {/* <GatsbyImage className="hero-car" image={heroData?.carImage?.fluid} /> */}
      </div>
      <div className="right-logo">
        <RightSideGoodingLogo />
      </div>
      <div className="icons-section">
        <IconsSection iconsData={iconsData} />
      </div>
      <div className="left-logo">
        <LeftSideGoodingLogo />
      </div>
      <CarCarousel carCarousel={carouselData} />
      <Articles articleData={articleData} />
      <SellCar slug={heroData?.buttonSlug} data={sellCarData} />
      <div className="multi-channel-container">
        <Multichannel
          multiChannelData={multiChannelData}
          multiChannelTitle={multiChannelTitle}
        />
      </div>
      <LastSection
        sellCarText={carData?.sellCarText}
        smallText={carData?.smallText}
        sellCarTags={carData?.sellCarTags}
        slug={sellCarData?.buttonSlug}
        buttonText={sellCarData?.buttonTitle}
      />
      <Footer />
    </div>
  )
}
export default Car
