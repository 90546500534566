import React from "react"

export const Multichannel = ({ multiChannelData, multiChannelTitle }) => (
  <div className="multi-channel">
    <h1>{multiChannelTitle}</h1>
    <div className="multi-channel-cards">
      {multiChannelData?.map(card => (
        <div
          className="multi-channel-card"
          style={{ background: `url(${card?.photo?.file?.url})` }}
        >
          <img src={card?.icon?.file?.url} />
          <h1>{card?.cardTitle}</h1>
          <p>{card?.cardSubTitle}</p>
        </div>
      ))}
    </div>
  </div>
)
